import {Component, Input, OnInit} from '@angular/core';
import {Prono} from "../../models/prono.model";
import {SessionService} from "../../services/session.service";
import * as PronosActions from '../../models/prono.action';
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import {ConfirmComponent} from "../confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {ParseService} from "../../services/parse.service";

@Component({
    selector: 'pronos',
    templateUrl: './pronos.component.html',
    styleUrls: ['./pronos.component.scss']
})
export class PronosComponent implements OnInit {
    @Input() prono: Prono;

    constructor(public session: SessionService, private store: Store<AppState>, private dialog: MatDialog, private parse: ParseService) {
        this.prono = new Prono();
    }

    ngOnInit(): void {

    }

    deleteProno() {
        const dialogRef = this.dialog.open(ConfirmComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result)
                this.store.dispatch(new PronosActions.DelProno(this.prono));
        });
    }

    validerProno(prono = this.prono) {
        prono.result = true;
        this.store.dispatch(new PronosActions.SetPronos(prono, false));
    }

    invaliderProno(prono = this.prono) {
        prono.result = false;
        this.store.dispatch(new PronosActions.SetPronos(prono, false));
    }

    hasEditRights() {
        return this.prono.account.id === this.session.accountConnecte.id ||
            this.session.accountConnecte.type.name === 'Admin';
    }

    determineResult(prono: Prono) {
        let result = true;
        if (prono.isCombine) {
            for (let combi of prono.combines) {
                result = result && combi.result;
            }
        }
        return result;
    }
}
