import * as SportActions from './sport.action';
import {Sport} from './sport.model';

export function SportReducer(state: Sport[], action: SportActions.Actions) {
    switch (action.type) {
        case SportActions.GET_SPORTS_SUCCES:
            return [
                new Sport({id: -1, name: 'Tableaux',
                    icon: 'assets/images/logo.png'}),
                ...action.payload
            ];
        case SportActions.GET_SCORES_SUCCES:
            return state.map((sport) => {
                if (sport.id === action.payload.id) {
                    sport.tauxWin = action.payload.tauxWin;
                    sport.coteMoy = action.payload.coteMoy;
                }
                return sport;
            });
        default:
            return state !== undefined ? state : [];
    }
}

