<mat-drawer-container>
    <mat-drawer [autoFocus]="false" #drawerleft mode="over" opened="false" position="start" style="width: 300px">
        <app-account></app-account>
    </mat-drawer>
    <mat-drawer-content fxLayout="row" fxLayout.lt-md="column">
        <div class="image-fond"
             [ngClass]="logoClass"></div>
        <div overlay></div>
        <button *ngIf="session.accountConnecte.id !== null" style="margin: 0" class="mat-neutral" id="drawer-right"
                mat-icon-button mat-raised-button (click)="sidenav='discord'; drawerright.toggle()">
            <h1 class="orange">
                <mat-icon>question_answer</mat-icon>
            </h1>
        </button>
        <button *ngIf="session.accountConnecte.id !== null && session.accountConnecte.type.name === 'Admin'"
                style="margin: 0" class="mat-neutral" id="drawer-right2" mat-icon-button mat-raised-button
                (click)="sidenav='article'; drawerright.toggle()">
            <h1 class="orange">
                <mat-icon>article</mat-icon>
            </h1>
        </button>
        <button *ngIf="session.accountConnecte.id !== null" style="margin: 0" class="mat-neutral" id="drawer-left"
                mat-icon-button mat-raised-button (click)="drawerleft.toggle()">
            <h1 class="orange">
                <mat-icon>account_circle</mat-icon>
            </h1>
        </button>
        <router-outlet></router-outlet>
    </mat-drawer-content>
    <mat-drawer [autoFocus]="false" #drawerright mode="over" opened="false" position="end" style="width: 300px">
        <app-tchat *ngIf="sidenav === 'discord'"></app-tchat>
        <app-article *ngIf="sidenav === 'article'"></app-article>
    </mat-drawer>
</mat-drawer-container>
