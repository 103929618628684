import {Action} from '@ngrx/store';
import {Sport} from './sport.model';

export const GET_SPORTS = '[SPORT] Get';
export const GET_SPORTS_SUCCES = '[SPORT] Get Succes';
export const GET_SCORES = '[SPORT] Get Scores';
export const GET_SCORES_SUCCES = '[SPORT] Get Scores Succes';

export class GetSports implements Action {
    readonly type = GET_SPORTS;

    constructor() {
    }
}

export class GetSportsSucces implements Action {
    readonly type = GET_SPORTS_SUCCES;

    constructor(public payload: Sport[]) {
    }
}

export class GetScores implements Action {
    readonly type = GET_SCORES;

    constructor(public payload: Sport) {
    }
}

export class GetScoresSucces implements Action {
    readonly type = GET_SCORES_SUCCES;

    constructor(public payload: Sport) {
    }
}

export type Actions =
    GetSports |
    GetSportsSucces |
    GetScores |
    GetScoresSucces;
