import {Entite} from "./entite.model";

export class Article extends Entite {
    static REST: string = 'articles';
    title: string;
    content: string;
    isFreeAccess: boolean;
    isAdminAccess: boolean;

    constructor({
                    id = null, title = null, content = null, isFreeAccess = null, isAdminAccess = null
                } = {}) {
        super({id});
        this.title = title;
        this.content = content;
        this.isFreeAccess = isFreeAccess;
        this.isAdminAccess = isAdminAccess;
    }
}
