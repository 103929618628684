import {Entite} from './entite.model';

export class Sport extends Entite {
    static REST: string = 'sports';
    name: string;
    icon: string;
    tauxWin: number;
    coteMoy: number;

    constructor({id = null, name = null, icon = null, tauxWin = null, coteMoy = null} = {}) {
        super({id});
        this.name = name;
        this.icon = icon;
        this.tauxWin = tauxWin;
        this.coteMoy = coteMoy;
    }
}
