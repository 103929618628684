<form fxLayout="row wrap" [formGroup]="passFormGroup">
    <inputdatepicker fxFlex="100"
                     [passControl]="passFormGroup.controls.dateMatch"
                     [passFormGroup]="passFormGroup"
                     [passControlName]="'dateMatch'"
                     [placeholder]="'Date du match'"
                     class="mat-form-field">
    </inputdatepicker>
    <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Cote</mat-label>
        <input autocomplete="off" matInput formControlName="cote" [textMask]="mask.numberMask">
        <mat-error *ngFor="let error of passFormGroup.controls.cote.errors | keyvalue">
            <span *ngIf="passFormGroup.controls.cote.hasError(error.key)">{{errorMessages[error.key]}}</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Condition de victoire</mat-label>
        <input autocomplete="off" matInput formControlName="condition">
        <mat-error *ngFor="let error of passFormGroup.controls.condition.errors | keyvalue">
            <span *ngIf="passFormGroup.controls.condition.hasError(error.key)">{{errorMessages[error.key]}}</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Equipe 1</mat-label>
        <input autocomplete="off" matInput formControlName="team1">
        <mat-error *ngFor="let error of passFormGroup.controls.team1.errors | keyvalue">
            <span *ngIf="passFormGroup.controls.team1.hasError(error.key)">{{errorMessages[error.key]}}</span>
        </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Equipe 2</mat-label>
        <input autocomplete="off" matInput formControlName="team2">
        <mat-error *ngFor="let error of passFormGroup.controls.team2.errors | keyvalue">
            <span *ngIf="passFormGroup.controls.team2.hasError(error.key)">{{errorMessages[error.key]}}</span>
        </mat-error>
    </mat-form-field>
</form>
