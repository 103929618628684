import {Action} from '@ngrx/store';
import {Jeton} from './jeton.model';
import {Identifiant} from "./identifiant.interface";

export const CONNEXION_JETON = '[JETON] Connexion';
export const CONNEXION_JETON_SUCCES = '[JETON] Succes Connexion';
export const RAFRAICHIR_JETON = '[JETON] Rafraichir';
export const RAFRAICHIR_JETON_SUCCES = '[JETON] Succes Rafraichir';
export const FERMER_JETON = '[JETON] Fermer';
export const FERMER_JETON_SUCCES = '[JETON] Succes Fermer';

export class ConnexionJeton implements Action {
    readonly type = CONNEXION_JETON;

    constructor(public payload: Identifiant) {
    }
}

export class ConnexionJetonSucces implements Action {
    readonly type = CONNEXION_JETON_SUCCES;

    constructor(public payload: Jeton) {
    }
}

export class RafraichirJeton implements Action {
    readonly type = RAFRAICHIR_JETON;

    constructor(public payload: Jeton) {
    }
}

export class RafraichirJetonSucces implements Action {
    readonly type = RAFRAICHIR_JETON_SUCCES;

    constructor(public payload: Jeton, public message = null) {
    }
}

export class FermerJeton implements Action {
    readonly type = FERMER_JETON;

    constructor(public payload: string = 'À bientôt !') {
    }
}

export class FermerJetonSucces implements Action {
    readonly type = FERMER_JETON_SUCCES;

    constructor(public payload: string = 'À bientôt !') {
    }
}

export type Actions =
    ConnexionJeton
    | ConnexionJetonSucces
    | RafraichirJeton
    | RafraichirJetonSucces
    | FermerJeton
    | FermerJetonSucces;
