import {Type} from "./type.model";
import {Entite} from "./entite.model";

export class Account extends Entite {
    static REST: string = 'accounts';
    id: number;
    firstname: string;
    name: string;
    pseudo: string;
    email: string;
    type: Type;
    pwd: string;
    active: string;

    constructor({
                    id = null, firstname = null, name = null, pseudo = null,
                    email = null, type = null, pwd = null, active = null
                } = {}) {
        super({id});
        this.firstname = firstname;
        this.name = name;
        this.pseudo = pseudo;
        this.email = email;
        this.type = type !== null ? new Type(type) : new Type();
        this.pwd = pwd;
        this.active = active;
    }
}
