import * as JetonActions from '../../models/jeton.action';
import {Component, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Errors} from "../../enums/errors.enum";
import {ResponsiveService} from "../../services/responsive.service";
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import {SportService} from "../../services/sport.service";
import {Sport} from "../../models/sport.model";
import {Router} from "@angular/router";

@Component({
    selector: 'connexion',
    templateUrl: './connexion.component.html',
    styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {
    // FormGroup de connexion
    connexionForm: FormGroup;
    messagesErreur: any = Errors;

    constructor(public responsive: ResponsiveService, private sport: SportService, private store: Store<AppState>, private router: Router) {
        // Création du FormGroup de connexion
        this.initForms();
    }

    public ngOnInit(): void {

    }

    /**
     * Création du formulaire
     */
    private initForms(): void {
        this.connexionForm = new FormGroup({
            pseudo: new FormControl('', [Validators.required]),
            pwd: new FormControl('', [Validators.required])
        });
    }

    /**
     * Envoi le formulaire de connexion au backend
     * (Bouton Connexion)
     */
    public envoyerFormulaire(): void {
        if (this.connexionForm.valid) {
            this.store.dispatch(new JetonActions.ConnexionJeton(this.connexionForm.value));
        }
    }

    moveToHome() {
        this.router.navigate(['home']);
    }
}
