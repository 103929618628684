import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs/internal/observable/of';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EntiteService} from '../services/entite.services';
import * as PoubelleActions from './poubelle.action';
import * as PronoActions from './prono.action';
import * as SportActions from './sport.action';
import {Prono} from './prono.model';
import {ParseService} from "../services/parse.service";
import {Router} from "@angular/router";

@Injectable()
export class PronoEffect {
    getPronos$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.GET_PRONOS),
        switchMap((action: PronoActions.GetPronos) =>
            this.entite.getEntites(null, Prono, Prono.REST)
                .pipe(map((pronos: Prono[]) => ({
                    type: PronoActions.GET_PRONOS_SUCCES,
                    payload: pronos
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    getProno$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.GET_PRONO),
        switchMap((action: PronoActions.GetProno) =>
            this.entite.getEntite(null, Prono, Prono.REST, action.payload.id.toString())
                .pipe(map((prono: Prono) => ({
                    type: PronoActions.GET_PRONO_SUCCES,
                    payload: prono
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    delProno$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.DEL_PRONO),
        switchMap((action: PronoActions.DelProno) =>
            this.entite.delEntite(Prono, Prono.REST, action.payload.id.toString())
                .pipe(map(_ => ({
                    type: PronoActions.DEL_PRONO_SUCCES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    setPronos$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.SET_PRONOS),
        switchMap((action: PronoActions.SetPronos) => {
            return this.entite.postEntite(this.parse.entity(action.payload), Prono, Prono.REST)
                .pipe(map(_ => (action.refresh ? {
                    type:  PronoActions.SET_PRONO_SUCCES
                } : {
                    type: SportActions.GET_SCORES,
                    payload: action.payload.sport
                })));
        })));

    setCombines$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.SET_COMBINES),
        switchMap((action: PronoActions.SetCombines) => {
            return this.entite.postEntite(this.parse.entity(action.payload), Prono, Prono.REST, 'combines')
                .pipe(map(_ => ({
                    type: PronoActions.SET_COMBINES_SUCCES
                })));
        })));

    reloadPronos$ = createEffect(() => this.actions$.pipe(
        ofType(PronoActions.SET_PRONO_SUCCES, PronoActions.SET_COMBINES_SUCCES, PronoActions.DEL_PRONO_SUCCES),
        switchMap((action: any) => {
            this.router.navigate(['liste']);
            return of(true)
                .pipe(map(_ => ({
                    type: PronoActions.GET_PRONOS
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))})));


    constructor(private actions$: Actions, private router: Router, private entite: EntiteService, private parse: ParseService) {
    }
}
