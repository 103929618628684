import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import * as PoubelleActions from './poubelle.action';
import * as JetonActions from './jeton.action';
import {of} from 'rxjs';
import {Jeton} from "./jeton.model";
import {SessionService} from "../services/session.service";
import {EntiteService} from "../services/entite.services";
import {LocalStorageService} from "../services/local-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EnvService} from "../services/env.service";
import {SnackbarService} from "../services/snackbar.service";
import {Sport} from "./sport.model";
import {SportService} from "../services/sport.service";

@Injectable()
export class JetonEffect {
    connexionJeton$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.CONNEXION_JETON),
        switchMap((action: JetonActions.ConnexionJeton) =>
            this.entite.postEntite(action.payload, Jeton, Jeton.REST, 'connexion')
                .pipe(map((jeton: Jeton) => ({
                    type: JetonActions.CONNEXION_JETON_SUCCES,
                    payload: jeton
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    connexionJetonSucces$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.CONNEXION_JETON_SUCCES),
        tap((action: JetonActions.ConnexionJetonSucces) => {
            this.session.vientDeSeConnecter = true;
            this.local.setCItem(`${this.env.getTypeJeton()}_jeton_${this.env.getEnvironment()}`, action.payload.value);
            this.snack.openSuccess('Bonjour ' + action.payload.account.pseudo + ' !');
            this.router.navigate(['/']); // REDIRECTION VERS L'APP
        })), {dispatch: false});

    rafraichirJeton$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.RAFRAICHIR_JETON),
        switchMap((action: JetonActions.RafraichirJeton) =>
            this.entite.getEntite(null, Jeton, Jeton.REST)
                .pipe(map(jeton => ({type: JetonActions.RAFRAICHIR_JETON_SUCCES, payload: jeton})),
                    catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    rafraichirJetonSucces$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.RAFRAICHIR_JETON_SUCCES),
        tap((action: JetonActions.RafraichirJetonSucces) => {
            const randInt = Math.floor(Math.random() * (5));
            let message = action.message;
            if (message === null) {
                switch (randInt) {
                    case 0:
                        message = 'Bonjour ' + action.payload.account.pseudo + ' !';
                        break;
                    case 1:
                        message = 'Enfin de retour ' + action.payload.account.pseudo + ' !';
                        break;
                    case 2:
                        message = 'Après le prêt à porter, le prêt à parier.';
                        break;
                    case 3:
                        message = action.payload.account.pseudo + ' n\'oublie pas de parler de SportsUsPronos autour de toi.';
                        break;
                    case 4:
                        message = 'Parions ensemble ' + action.payload.account.pseudo + ' !';
                        break;
                }
            }
            this.snack.openSuccess(message);
            // AUCUNE REDIRECTION AU RAFRAICHIR DU JETON
        })), {dispatch: false});

    fermerJeton$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.FERMER_JETON),
        switchMap((action: JetonActions.FermerJeton) => {
            this.local.deleteCItem(`${this.env.getTypeJeton()}_jeton_${this.env.getEnvironment()}`);
            this.local.clearStorage();
            this.sport.select(new Sport());
            this.sport.refDrawerLeft.close();
            this.sport.refDrawerRight.close();
            return this.entite.getEntite(null, Jeton, Jeton.REST, 'revoque').pipe(map(() => ({
                type: JetonActions.FERMER_JETON_SUCCES,
                payload: action.payload
            })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
        })));

    fermerJetonSucces$ = createEffect(() => this.actions$.pipe(
        ofType(JetonActions.FERMER_JETON_SUCCES),
        tap((action: JetonActions.FermerJetonSucces) => {
            if (action.payload !== '') {
                this.snack.openSuccess(action.payload);
            }
            this.router.navigate(['home']); // REDIRECTION VERS LA PAGE DE CONNEXION
        })), {dispatch: false});

    constructor(private actions$: Actions, private sport: SportService, private session: SessionService, private entite: EntiteService, private local: LocalStorageService,
                private router: Router, private current: ActivatedRoute, private env: EnvService, private snack: SnackbarService) {
    }
}
