import {Component, Input, OnInit} from '@angular/core';
import {MaskService} from '../../services/mask.service';
import {FormGroup} from '@angular/forms';
import {Errors} from '../../enums/errors.enum';

@Component({
    selector: 'form-prono',
    templateUrl: './form-prono.component.html',
    styleUrls: ['./form-prono.component.scss']
})
export class FormPronoComponent implements OnInit {

    @Input() passFormGroup: FormGroup;
    errorMessages = Errors;

    constructor(public mask: MaskService) {
    }

    ngOnInit(): void {
    }

}
