import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Jeton} from '../models/jeton.model';
import {EnvService} from "./env.service";
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor(private cookies: CookieService, private envService: EnvService) {
    }
    // ---------- LOCAL STORAGE
    setLSString(item: string, str: string) {
        localStorage.setItem(item, str);
    }
    getLSItem(item: string) {
        const storedItem = JSON.parse(localStorage.getItem(item));
        if (storedItem === null) {
            return false;
        }
        return storedItem;
    }
    existItem(item: string) {
        return JSON.parse(localStorage.getItem(item)) !== null;
    }
    deleteItem(item: string) {
        if (this.existItem(item)) {
            localStorage.removeItem(item);
        }
    }
    setLSItem(item: string, obj: object) {
        localStorage.setItem(item, JSON.stringify(obj));
    }
    clearStorage() {
        localStorage.clear();
    }
    // ----------- COOKIES
    setCItem(item: string, data: string) {
        this.cookies.set(item, data, null, '/', this.envService.getDomain());
    }
    deleteCItem(item: string) {
        this.cookies.delete(item, '/', this.envService.getDomain());
    }
    getCEntity(entity: string): any {
        const storedItemStr = this.cookies.get(entity);
        if (storedItemStr.trim().length === 0) {
            return false;
        }
        if (entity.indexOf('jeton') !== -1) {
            return new Jeton({value: storedItemStr});
        } else {
            switch (entity) {
                default:
                    // console.log('The entity ' + entity + ' does not exists.');
                    return false;
            }
        }
    }
}