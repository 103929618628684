<div [formGroup]="passFormGroup">
    <mat-form-field [style.opacity]="readonly ? '1' : '1'" style="width: 100%;" appearance="outline">
        <mat-label>{{placeholder}}</mat-label>
        <input matInput [matDatepicker]="matpicker" #inputmatpicker
               [formControlName]="passControlName" [name]="passControlName" mask-date (keydown.enter)="send($event)"
               [required]="required">
        <mat-datepicker-toggle matSuffix [disabled]="readonly" [for]="matpicker"></mat-datepicker-toggle>
        <mat-datepicker #matpicker (closed)="send()"></mat-datepicker>
        <mat-error *ngFor="let error of passControl.errors | keyvalue">
            <span *ngIf="passControl.hasError(error.key)">{{errorMessages[error.key]}}</span>
        </mat-error>
    </mat-form-field>
</div>
