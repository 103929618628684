import {Component, OnInit} from '@angular/core';
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import {Prono} from 'src/app/models/prono.model';
import {Observable} from 'rxjs/internal/Observable';
import {SportService} from "../../services/sport.service";
import * as PronoActions from '../../models/prono.action';
import {SessionService} from "../../services/session.service";
import {Article} from "../../models/article.model";

@Component({
    selector: 'app-liste-pronos',
    templateUrl: './liste-pronos.component.html',
    styleUrls: ['./liste-pronos.component.scss']
})
export class ListePronosComponent implements OnInit {

    pronos$: Observable<Prono[]>;
    articles$: Observable<Article[]>;

    constructor(private store: Store<AppState>, public sport: SportService, public session: SessionService) {
        this.pronos$ = this.store.select('pronos');
        this.articles$ = this.store.select('articles');
        this.store.dispatch(new PronoActions.GetPronos());
    }

    ngOnInit(): void {
    }
}
