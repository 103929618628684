import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PronosComponent} from './components/pronos/pronos.component';
import {FooterMobileComponent} from './components/footer-mobile/footer-mobile.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {FlexLayoutModule} from "@angular/flex-layout";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatCardModule} from "@angular/material/card";
import {HeaderMobileComponent} from './components/header-mobile/header-mobile.component';
import {ConnexionComponent} from './components/connexion/connexion.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {InputMotDePasseComponent} from "./components/input-mot-de-passe/input-mot-de-passe.component";
import {ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import {JetonReducer} from "./models/jeton.reducer";
import {SportsusComponent} from './components/sportsus/sportsus.component';
import {EffectsModule} from "@ngrx/effects";
import {JetonEffect} from "./models/jeton.effect";
import {MatDialogModule} from "@angular/material/dialog";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {CustomInterceptor} from "./services/custom.interceptor";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getFrenchPaginatorIntl} from "./services/french-paginator-intl";
import {RefreshResolver} from "./services/refresh.resolver";
import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {ErrorHandlerService} from "./services/error.handler.service";
import {SportPipe} from "./services/sport.pipe";
import {PronoReducer} from "./models/prono.reducer";
import {PronoEffect} from "./models/prono.effect";
import {SportReducer} from "./models/sport.reducer";
import {SportEffect} from "./models/sport.effect";
import {MatExpansionModule} from "@angular/material/expansion";
import {ListePronosComponent} from './components/liste-pronos/liste-pronos.component';
import {EditPronosComponent} from './components/edit-pronos/edit-pronos.component';
import {InputdatepickerComponent} from './components/inputdatepicker/inputdatepicker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MaskDateDirective} from "./directives/mask-date.directive";
import {TextMaskModule} from "angular2-text-mask";
import {AngularFireModule} from "@angular/fire";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {fireconf} from '../environments/fireconf';
import {TchatComponent} from './components/tchat/tchat.component';
import {AccountComponent} from './components/account/account.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AccountEffect} from "./models/account.effect";
import {MatDividerModule} from "@angular/material/divider";
import {ConfirmComponent} from './components/confirm/confirm.component';
import {HomeComponent} from './components/home/home.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FormPronoComponent} from './components/form-prono/form-prono.component';
import {ArticleComponent} from "./components/article/article.component";
import {ArticleReducer} from "./models/article.reducer";
import {ArticleEffect} from "./models/article.effect";
import {EditorModule} from "@tinymce/tinymce-angular";
import {Safe} from "./pipes/safeHtml.pipe";
import {ConfirmationDialog} from "./components/confirmation-modale/confirmation-modale.component";
import {MatRadioModule} from "@angular/material/radio";

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@NgModule({
    declarations: [
        AppComponent,
        PronosComponent,
        FooterMobileComponent,
        HeaderMobileComponent,
        ConnexionComponent,
        InputMotDePasseComponent,
        SportsusComponent,
        SportPipe,
        ListePronosComponent,
        EditPronosComponent,
        InputdatepickerComponent,
        MaskDateDirective,
        TchatComponent,
        AccountComponent,
        ArticleComponent,
        ConfirmComponent,
        HomeComponent,
        FormPronoComponent,
        Safe,
        ConfirmationDialog
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatSidenavModule,
        MatToolbarModule,
        FlexLayoutModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatButtonModule,
        HttpClientModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatDatepickerModule,
        MatDividerModule,
        MatSnackBarModule,
        MatExpansionModule,
        AngularFireModule.initializeApp(fireconf.firetchat),
        AngularFireDatabaseModule,
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        ReactiveFormsModule, StoreModule.forRoot({
            jeton: JetonReducer,
            pronos: PronoReducer,
            sports: SportReducer,
            articles: ArticleReducer
        }, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        EffectsModule.forRoot([JetonEffect, PronoEffect, SportEffect, AccountEffect, ArticleEffect]),
        TextMaskModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}), MatSlideToggleModule, EditorModule, MatRadioModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: ErrorHandlerService},
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 10000}},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()},
        RefreshResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
