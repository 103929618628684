import {Pipe, PipeTransform} from '@angular/core';
import {Prono} from '../models/prono.model';
import {Sport} from '../models/sport.model';

@Pipe({name: 'sport'})
export class SportPipe implements PipeTransform {
    transform(pronos: Prono[], selectedSport: Sport): Prono[] {
        return pronos.filter((prono: Prono) => {
            return selectedSport === undefined || selectedSport === null ? true : prono.sport.id === selectedSport.id;
        });
    }
}