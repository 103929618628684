import {Injectable} from '@angular/core';
import {Sport} from '../models/sport.model';
import {BehaviorSubject} from "rxjs";
import {MatSidenav} from "@angular/material/sidenav";

@Injectable({
    providedIn: 'root'
})
export class SportService {

    public refDrawerLeft: MatSidenav;
    public refDrawerRight: MatSidenav;
    public selectedSport$: BehaviorSubject<Sport>;
    nothingSelected: Boolean = true;

    constructor() {
        this.selectedSport$ = new BehaviorSubject<Sport>(new Sport());
    }

    select(sport: Sport) {
        if (sport !== null) {
            this.selectedSport$.next(sport);
            this.nothingSelected = sport.id === null;
        }
    }
}
