import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-sportsus',
    templateUrl: './sportsus.component.html',
    styleUrls: ['./sportsus.component.scss']
})
export class SportsusComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
