export const fireconf = {
    firetchat: {
        apiKey: "AIzaSyCZ3-lpccz76_RFBtkeDOdKLo1p3zsBciE",
        authDomain: "sportsuspronos-aed33.firebaseapp.com",
        projectId: "sportsuspronos-aed33",
        storageBucket: "sportsuspronos-aed33.appspot.com",
        messagingSenderId: "1070407730456",
        appId: "1:1070407730456:web:7cd26e63f90e296831ca6d"
    }
}
