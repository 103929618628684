import {Moment} from 'moment';
import {Entite} from './entite.model';
import {Sport} from './sport.model';
import {Account} from './account.model';
import * as moment from 'moment';

export class Prono extends Entite {
    static REST: string = 'pronos';
    sport: Sport;
    cote: number;
    team1: string;
    team2: string;
    condition: string;
    result: boolean;
    datePublication: Moment;
    dateMatch: Moment;
    account: Account;
    isCombine: boolean;
    isFreeAccess: boolean;
    combines: Prono[];

    constructor({
                    id = null,
                    sport = null,
                    cote = null,
                    team1 = null,
                    team2 = null,
                    condition = null,
                    result = null,
                    datePublication = null,
                    dateMatch = null,
                    account = null,
                    isCombine = null,
                    isFreeAccess = null
                } = {}) {
        super({id});
        this.entitesLiees = [{
            classe: Prono,
            nomPropriete: 'combines'
        }];
        this.sport = sport !== null ? new Sport(sport) : new Sport();
        this.cote = cote;
        this.team1 = team1;
        this.team2 = team2;
        this.condition = condition;
        this.result = result;
        this.datePublication = datePublication !== null ? moment(new Date(datePublication)) : moment(new Date());
        this.dateMatch = dateMatch !== null ? moment(new Date(dateMatch)) : moment(new Date());
        this.account = account !== null ? new Account(account) : new Account();
        this.isCombine = isCombine;
        this.isFreeAccess = isFreeAccess;
        this.combines = [];
    }
}
