import {EntiteLiee} from './entite-liee.interface';

export class Entite {
    static REST: string;
    id: number;
    entitesLiees: EntiteLiee[];

    constructor({
                    id = null,
                } = {}) {
        this.id = id;
        this.entitesLiees = [];
    }
}
