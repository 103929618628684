import {Action} from '@ngrx/store';
import {Article} from './article.model';
import {Sport} from "./sport.model";

export const GET_ARTICLES = '[ARTICLE] Get';
export const GET_ARTICLES_SUCCES = '[ARTICLE] Get Succes';
export const SET_ARTICLE = '[ARTICLE] Set';
export const SET_ARTICLE_SUCCES = '[ARTICLE] Set Succes';
export const DEL_ARTICLE = '[ARTICLE] Del';
export const DEL_ARTICLE_SUCCES = '[ARTICLE] Del Succes';

export class SetArticle implements Action {
    readonly type = SET_ARTICLE;

    constructor(public payload: Article) {
    }
}

export class SetArticleSucces implements Action {
    readonly type = SET_ARTICLE_SUCCES;

    constructor(public payload: Article[]) {
    }
}

export class DelArticle implements Action {
    readonly type = DEL_ARTICLE;

    constructor(public payload: Article) {
    }
}

export class DelArticleSucces implements Action {
    readonly type = DEL_ARTICLE_SUCCES;

    constructor(public payload: Article[]) {
    }
}

export class GetArticles implements Action {
    readonly type = GET_ARTICLES;

    constructor() {
    }
}

export class GetArticlesSucces implements Action {
    readonly type = GET_ARTICLES_SUCCES;

    constructor(public payload: Sport[]) {
    }
}

export type Actions =
    SetArticle |
    SetArticleSucces |
    GetArticles |
    GetArticlesSucces;
