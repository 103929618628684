import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs/internal/observable/of';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EntiteService} from '../services/entite.services';
import * as PoubelleActions from './poubelle.action';
import * as SportActions from './sport.action';
import {Sport} from './sport.model';

@Injectable()
export class SportEffect {
    getSports$ = createEffect(() => this.actions$.pipe(
        ofType(SportActions.GET_SPORTS),
        switchMap((action: SportActions.GetSports) =>
            this.entite.getEntites(null, Sport, Sport.REST)
                .pipe(map((sports: Sport[]) => ({
                    type: SportActions.GET_SPORTS_SUCCES,
                    payload: sports
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    getScores$ = createEffect(() => this.actions$.pipe(
        ofType(SportActions.GET_SCORES),
        switchMap((action: SportActions.GetScores) =>
            this.entite.getEntite(null, Sport, Sport.REST, 'scores', action.payload.id.toString())
                .pipe(map((sport: Sport) => ({
                    type: SportActions.GET_SCORES_SUCCES,
                    payload: sport
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    constructor(private actions$: Actions, private entite: EntiteService) {
    }
}
