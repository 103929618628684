import {Component, OnInit} from '@angular/core';
import {first} from "rxjs/operators";
import {SportService} from "../../services/sport.service";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../services/snackbar.service";
import {MaskService} from "../../services/mask.service";
import {Errors} from "../../enums/errors.enum";
import {Store} from "@ngrx/store";
import {AppState} from "../../app.state";
import * as PronosActions from "../../models/prono.action";
import {Prono} from "../../models/prono.model";
import {Actions, ofType} from "@ngrx/effects";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../services/session.service";
import {KillsubscribeService} from "../../services/killsubscribe.service";
import *as moment from "moment";

@Component({
    selector: 'app-edit-pronos',
    templateUrl: './edit-pronos.component.html',
    styleUrls: ['./edit-pronos.component.scss']
})
export class EditPronosComponent implements OnInit {

    formProno: FormGroup;
    formCombines: FormGroup[];
    errorMessages = Errors;
    prono: Prono;

    constructor(private sport: SportService, private snack: SnackbarService, public mask: MaskService,
                private store: Store<AppState>, private actions: Actions, private router: Router,
                private route: ActivatedRoute, private session: SessionService, private ks: KillsubscribeService) {
        this.prono = new Prono();
        this.formProno = this.newPronoForm();
        this.formCombines = [];

        this.formProno.controls.isCombine.valueChanges.subscribe((isCombine) => {
             if (isCombine && this.formCombines.length <= 0) {
                 this.ajouterProno();
             }
        });

        const paramId = this.route.snapshot.paramMap.get('idprono') ? this.route.snapshot.paramMap.get('idprono') : null;
        if (paramId !== null) {
            this.store.dispatch(new PronosActions.GetProno(new Prono({id: paramId})));
        }
        this.ks.ks = this.actions.pipe(ofType(PronosActions.GET_PRONO_SUCCES)).subscribe((action: PronosActions.GetPronoSucces) => {
            this.prono = action.payload;
            this.initCombine();
        });
    }

    ngOnInit(): void {

    }

    initCombine() {
        if (this.prono.isCombine) {
            for (let combine of this.prono.combines) {
                let formCombine = this.newPronoForm();
                this.initFormProno(formCombine, combine);
                // @ts-ignore
                formCombine.ref = combine;
                this.formCombines.push(formCombine);
            }
        }
        this.initFormProno(this.formProno, this.prono);
    }

    newPronoForm() {
        return new FormGroup({
            dateMatch: new FormControl('', [Validators.required]),
            cote: new FormControl('', [Validators.required]),
            condition: new FormControl('', [Validators.required]),
            team1: new FormControl('', [Validators.required]),
            team2: new FormControl('', [Validators.required]),
            isCombine: new FormControl(false),
            isFreeAccess: new FormControl(false),
        });
    }

    initFormProno(formGroup: FormGroup, prono: Prono) {
        formGroup.controls.dateMatch.setValue(prono.dateMatch);
        formGroup.controls.cote.setValue(prono.cote);
        formGroup.controls.condition.setValue(prono.condition);
        formGroup.controls.team1.setValue(prono.team1);
        formGroup.controls.team2.setValue(prono.team2);
        formGroup.controls.isCombine.setValue(prono.isCombine);
        formGroup.controls.isFreeAccess.setValue(prono.isFreeAccess);
    }

    validerCombine() {
        if (this.formCombines.length <= 0) {
            this.snack.openError('Un combiné ne peut pas être vide.');
            return;
        }

        for (const formCombine of this.formCombines) {
            if (formCombine.invalid) {
                formCombine.markAllAsTouched();
                this.snack.openError('Il reste des champs vides.');
                return;
            }
        }

        this.ks.ks = this.sport.selectedSport$.pipe(first()).subscribe((selectedSport) => {
            const prono = new Prono({...this.prono, ...this.formProno.getRawValue(), account: this.session.accountConnecte});
            prono.sport = selectedSport;

            let coteCombine = 1;
            let plusTard: moment.Moment = null;

            for (const formCombine of this.formCombines) {
                // @ts-ignore
                const combi = new Prono({...formCombine.ref, ...formCombine.getRawValue(), account: this.session.accountConnecte});
                combi.sport = selectedSport;
                coteCombine *= parseFloat(''+combi.cote);
                if (plusTard === null) {
                    plusTard = combi.dateMatch;
                } else if (combi.dateMatch.diff(plusTard) > 0) {
                    plusTard = combi.dateMatch;
                }
                prono.combines.push(combi);
            }

            prono.cote = coteCombine;
            prono.dateMatch = plusTard;

            this.store.dispatch(new PronosActions.SetCombines(prono));
        });
    }

    validerProno() {
        if (this.formProno.invalid) {
            this.formProno.markAllAsTouched();
            this.snack.openError('Il reste des champs vides.');
            return;
        }

        this.ks.ks = this.sport.selectedSport$.pipe(first()).subscribe((selectedSport) => {
            const prono = new Prono({...this.prono, ...this.formProno.getRawValue(), account: this.session.accountConnecte});
            prono.sport = selectedSport;
            this.store.dispatch(new PronosActions.SetPronos(prono));
        });
    }

    ajouterProno() {
        this.formCombines.push(this.newPronoForm());
    }

    valider() {
        if (this.formProno.controls.isCombine.value) {
            this.validerCombine();
        } else {
            this.validerProno();
        }
    }

    supprimerProno(index: number) {
        this.formCombines.splice(index, 1);
    }

    toMoment(dateMatch: AbstractControl) {
        return dateMatch.value ? moment(dateMatch.value) : moment(new Date());
    }
}
