import {Injectable} from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {TextMaskConfig} from 'angular2-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
@Injectable({
    providedIn: 'root'
})
export class MaskService {
    // @https://github.com/text-mask/text-mask/tree/master/addons
    /*
    These can be passed as a mask to Text Mask.
    createNumberMask
    createNumberMask returns a numberMask function that will format user input as currency. createNumberMask accepts an object with the following keys:
    prefix (string): what to display before the amount. Defaults to '$'.
    suffix (string): what to display after the amount. Defaults to empty string.
    includeThousandsSeparator (boolean): whether or not to separate thousands. Defaults to to true.
    thousandsSeparatorSymbol (string): character with which to separate thousands. Default to ','.
    allowDecimal (boolean): whether or not to allow the user to enter a fraction with the amount. Default to false.
    decimalSymbol (string): character that will act as a decimal point. Defaults to '.'
    decimalLimit (number): how many digits to allow after the decimal. Defaults to 2
    integerLimit (number): limit the length of the integer number. Defaults to null for unlimited
    requireDecimal (boolean): whether or not to always include a decimal point and placeholder for decimal digits after the integer. Defaults to false.
    allowNegative (boolean): whether or not to allow negative numbers. Defaults to false
    allowLeadingZeroes (boolean): whether or not to allow leading zeroes. Defaults to false
     */
    // tslint:disable-next-line:variable-name
    private readonly _percentMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _entierpositifpercentMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _percentmilliemeprecisionMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _euroMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _numberMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _entierpositifMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _emailMask: TextMaskConfig = emailMask;
    // tslint:disable-next-line:variable-name
    private readonly _ibanMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _bicMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _telephoneMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _codepostalMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _numeroagenceMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _sirenMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _siretMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _fullsiretMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _oriasMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _initialesMask: TextMaskConfig;
    // tslint:disable-next-line:variable-name
    private readonly _numRcsMask: TextMaskConfig;
    constructor() {
        const options = {
            guide: true,
            placeholderChar: '_',
            // placeholderChar: '\u2000',
            keepCharPositions: false
        };
        this._emailMask.guide = options.guide;
        this._emailMask.placeholderChar = options.placeholderChar;
        this._emailMask.keepCharPositions = options.keepCharPositions;
        this._percentMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '%',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: true,
                decimalSymbol: '.',
                decimalLimit: 2,
                allowNegative: false
            }), ...options
        };
        this._entierpositifpercentMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '%',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: false,
                allowNegative: false
            }), ...options
        };
        this._percentmilliemeprecisionMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '%',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: true,
                decimalSymbol: '.',
                decimalLimit: 3,
                allowNegative: false,
                requireDecimal: true
            }), ...options
        };
        this._euroMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '€',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: true,
                decimalSymbol: '.',
                decimalLimit: 2,
                allowNegative: true
            }), ...options
        };
        this._numberMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: true,
                decimalSymbol: '.',
                decimalLimit: 2,
                allowNegative: true
            }), ...options
        };
        this._entierpositifMask = {
            mask: createNumberMask({
                prefix: '',
                suffix: '',
                includeThousandsSeparator: true,
                thousandsSeparatorSymbol: ' ',
                allowDecimal: false,
                allowNegative: false
            }), ...options
        };
        this._ibanMask = {
            mask: [/[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ',
                /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /[a-zA-Z0-9]/, /\d/, /\d/, /[a-zA-Z0-9]/, ' ', /\d/, /\d/],
            ...options
        };
        /**
         * Donner une fonction à la place d'un seul mask permet de rendre optionnels X caractères
         */
        this._bicMask = {
            mask: (rawValue) => {
                if (rawValue.replace('_', '').length <= 8) {
                    return [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
                } else if (rawValue.replace('_', '').length === 9) {
                    return [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
                } else if (rawValue.replace('_', '').length === 10) {
                    return [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
                } else {
                    return [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];
                }
            },
            ...options
        };
        this._telephoneMask = {
            mask: [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
            ...options
        };
        this._codepostalMask = {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
            ...options
        };
        this._numeroagenceMask = {
            mask: [/\d/, /\d/, /\d/, /\d/],
            ...options
        };
        this._sirenMask = {
            mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
            ...options
        };
        this._siretMask = {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
            ...options
        };
        this._fullsiretMask = {
            mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
            ...options
        };
        this._oriasMask = {
            mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            ...options
        };
        this._initialesMask = {
            mask: [/[A-zÀ-ú]/, /[A-zÀ-ú]/],
            ...options
        };
        this._numRcsMask = {
            mask: [/R/, /C/, /S/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ',  /\d/, /\d/, /\d/, /\d/, /\d/],
            ...options
        };
    }
    get numberMask(): TextMaskConfig {
        return this._numberMask;
    }
    get entierpositifMask(): TextMaskConfig {
        return this._entierpositifMask;
    }
    get percentMask(): TextMaskConfig {
        return this._percentMask;
    }
    get entierpositifpercentMask(): TextMaskConfig {
        return this._entierpositifpercentMask;
    }
    get percentmilliemeprecisionMask(): TextMaskConfig {
        return this._percentmilliemeprecisionMask;
    }
    get euroMask(): TextMaskConfig {
        return this._euroMask;
    }
    get emailMask(): TextMaskConfig {
        return this._emailMask;
    }
    get ibanMask(): TextMaskConfig {
        return this._ibanMask;
    }
    get bicMask(): TextMaskConfig {
        return this._bicMask;
    }
    get telephoneMask(): TextMaskConfig {
        return this._telephoneMask;
    }
    get codepostalMask(): TextMaskConfig {
        return this._codepostalMask;
    }
    get numeroagenceMask(): TextMaskConfig {
        return this._numeroagenceMask;
    }
    get sirenMask(): TextMaskConfig {
        return this._sirenMask;
    }
    get siretMask(): TextMaskConfig {
        return this._siretMask;
    }
    get oriasMask(): TextMaskConfig {
        return this._oriasMask;
    }
    get initialesMask(): TextMaskConfig {
        return this._initialesMask;
    }
    get fullsiretMask(): TextMaskConfig {
        return this._fullsiretMask;
    }
    get numRcsMask(): TextMaskConfig {
        return this._numRcsMask;
    }
}
