import {Injectable} from '@angular/core';
import {Article} from '../models/article.model';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ArticleService {
    
    public selectedArticle$: BehaviorSubject<Article>;
    nothingSelected: Boolean = true;

    constructor() {
        this.selectedArticle$ = new BehaviorSubject<Article>(new Article());
    }

    select(article: Article) {
        if (article !== null) {
            this.selectedArticle$.next(article);
            this.nothingSelected = article.id === null;
        }
    }
}
