import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-tchat',
    templateUrl: './tchat.component.html',
    styleUrls: ['./tchat.component.scss']
})
export class TchatComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
