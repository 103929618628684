import * as ArticleActions from './article.action';
import {Article} from './article.model';

export function ArticleReducer(state: Article[], action: ArticleActions.Actions) {
    switch (action.type) {
        case ArticleActions.GET_ARTICLES_SUCCES:
            return action.payload;
        default:
            return state !== undefined ? state : [];
    }
}

