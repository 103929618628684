import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import * as JetonActions from '../models/jeton.action';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {SessionService} from './session.service';
import {AppState} from "../app.state";
import {EnvService} from "./env.service";
import {ResourceService} from "./resource.service";
import {LocalStorageService} from "./local-storage.service";
import {Subscription} from "rxjs";
import {KillsubscribeService} from "./killsubscribe.service";

@Injectable()

export class RefreshResolver implements Resolve<boolean> {
    constructor(private store: Store<AppState>, private router: Router, private local: LocalStorageService, private session: SessionService,
                private env: EnvService, private resource: ResourceService, private actions: Actions, private ks: KillsubscribeService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
        const jeton = this.local.getCEntity(`${this.env.getTypeJeton()}_jeton_${this.env.getEnvironment()}`);
        return new Promise((resolve, reject) => {
            if (this.session.vientDeSeConnecter) { // SI L'UTILISATEUR VIENT DE SE CONNECTER, PAS BESOIN DE TESTER
                this.session.vientDeSeConnecter = false;
                resolve(true);
            } else if (jeton) {
                if (route.url.length > 0 && route.url[0].path === 'connexion') {
                    // SI UN JETON EXISTE MAIS QUE LA ROUTE DE CONNEXION EST APPELÉE, ON REROUTE VERS L'APP.
                    this.router.navigate(['/']);
                    resolve(false);
                } else {
                    this.resource.setJeton(jeton); // SI UN JETON EXISTE ET QUE L'APP EST APPELÉE, ON TEST LA VALIDITÉ DU JETON
                    this.store.dispatch(new JetonActions.RafraichirJeton(jeton));
                    this.ks.ks = this.actions.pipe(ofType(JetonActions.RAFRAICHIR_JETON_SUCCES)).subscribe(() => { // SI LE JETON EST VALIDE
                        resolve(true);
                    });
                }
            } else { // S'IL N'EXISTE PAS DE JETON, ON APPLIQUE LE COMPORTEMENT DE LA DECONNEXION SANS MESSAGE D'ERREURS, SAUF SI ON EST DEJA SUR LA PAGE DE CONNEXION
                if (route.url.length === 0) {
                    // this.session.deconnexion('');
                    this.router.navigate(['home']);
                }
                resolve(false);
            }
        });
    }
}
