<div fxLayout="row wrap" style="margin-bottom: 80px;">
    <button fxFlex style="margin-right: 4px; margin-bottom: 16px; width: 100%" class="mat-neutral" mat-raised-button
            routerLink="/liste">
        <h2 class="bleu">Retour</h2>
    </button>
    <button fxFlex style="margin-left: 4px; margin-bottom: 16px; width: 100%" class="mat-neutral" mat-raised-button
            (click)="valider()">
        <h2 class="orange">Valider</h2>
    </button>
    <div fxFlex="100" style="margin-bottom: 16px;" fxLayout="row">
        <span fxFlex></span>
        <mat-slide-toggle fxFlex
                          [formControl]="formProno.controls.isFreeAccess"
                          color="primary">
            {{formProno.controls.isFreeAccess.value ? 'Visible par tous' : 'Visible par All Stars uniquement'}}
        </mat-slide-toggle>
        <span fxFlex></span>
    </div>
    <div fxFlex="100" style="margin-bottom: 16px;" fxLayout="row">
        <span fxFlex></span>
        <mat-slide-toggle fxFlex
                          [formControl]="formProno.controls.isCombine"
                          color="primary"
                          [disabled]="prono.id !== null">
            {{formProno.controls.isCombine.value ? 'Pari combiné' : 'Pari simple'}}
        </mat-slide-toggle>
        <span fxFlex></span>
    </div>
    <div fxFlex *ngIf="formProno.controls.isCombine.value" fxLayout="column">
        <mat-form-field fxFlex="100" appearance="outline">
            <mat-label>Nom du combiné</mat-label>
            <input autocomplete="off" matInput [formControl]="formProno.controls.condition">
            <mat-error *ngFor="let error of formProno.controls.condition.errors | keyvalue">
                <span *ngIf="formProno.controls.condition.hasError(error.key)">{{errorMessages[error.key]}}</span>
            </mat-error>
        </mat-form-field>
        <button fxFlex style="margin-left: 4px; margin-bottom: 16px; width: 100%" class="mat-neutral" mat-raised-button
                (click)="ajouterProno()">
            <h2 class="orange">Ajouter</h2>
        </button>
        <mat-accordion *ngFor="let formCombine of formCombines; let index = index;">
            <mat-expansion-panel style="line-height: 56px; padding-left: 16px;">
                <mat-expansion-panel-header>
                    <mat-panel-title style="margin: 0">
                        <h4 class="orange" style="margin: 0">{{toMoment(formCombine.controls.dateMatch).format('L')}}</h4>
                    </mat-panel-title>
                    <mat-panel-description style="margin: 0;">
                        <button style="margin: 0 16px;" class="mat-neutral" mat-icon-button mat-raised-button (click)="supprimerProno(index)">
                            <h1 class="orange"><mat-icon>delete</mat-icon></h1>
                        </button>
                        <span>Côte : {{formCombine.controls.cote.value}}</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div fxLayout="row wrap">
                    <form-prono [passFormGroup]="formCombine"></form-prono>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div *ngIf="!formProno.controls.isCombine.value">
        <form-prono [passFormGroup]="formProno"></form-prono>
    </div>
</div>
