<mat-toolbar fxLayout="row">
    <div fxFlex></div>
    <h2 class="bleu">MESSAGERIE</h2>
    <div fxFlex></div>
</mat-toolbar>
<mat-card>
    <h4>Discord et le Sport n'ont jamais été aussi proche !</h4>
    <p>Nous pensons intégrer içi le tchat discord dans un ou plusieurs salons dédiés à cette application de partage de pronostiques sportifs.</p>
    <p>Si vous pensez que ce tchat vous serait utile dans votre analyse des matchs, faites-le nous savoir et surtout.. financez-le avec vos dons et vos gains grâce à la communauté!</p>
</mat-card>
