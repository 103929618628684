<button id="ajouter" *ngIf="(sport.selectedSport$ | async).id !== -1 && session.isAdminOrRedac()"
        style="margin: 0 0 8px 0; width: 100%" class="mat-neutral" mat-raised-button routerLink="/fiche">
    <h2 class="orange">Ajouter</h2>
</button>

<mat-accordion multi *ngIf="(sport.selectedSport$ | async).id !== -1">
    <pronos style="overflow: hidden" *ngFor="let prono of (pronos$ | async) | sport : (sport.selectedSport$ | async)"
            [prono]="prono"></pronos>
</mat-accordion>

<div *ngIf="(sport.selectedSport$ | async).id === -1" fxLayout="column">
    <mat-card class="article" fxFlex="100" *ngFor="let article of articles$ | async">
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-icon *ngIf="!article.isFreeAccess">star_rate</mat-icon>
            <mat-card-title>{{article.title}}
                <span style="font-size: 0.6em !important; display: block;"
                      *ngIf="session.isAdminOrRedac()">Visible par {{article.isFreeAccess && article.isAdminAccess ? 'tout le monde' : this.article.isAdminAccess ? 'les all stars' : this.article.isFreeAccess ? 'les invités' : 'personne'}}.</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <br/>
            <p style="margin: 32px;" [innerHTML]="article.content | safeHtml"></p>
        </mat-card-content>
    </mat-card>
</div>
