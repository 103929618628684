<div style="text-align: center">
    <h2 class="bleu" style="display: inline">SPORTS US</h2>
    <h2 class="orange">PRONOS</h2>
</div>

<a href="https://discord.gg/zrtxRXHhys" target="_blank"
   style="display: block; margin-top: 40px;" fxLayoutAlign="center center">
    <img style="width: 40px; height: 40px; margin-right: 12px" src="../../../assets/images/discord.png">
    <span>Obtenez votre acces all star</span>
</a>

<div fxLayout="row wrap" style="margin-top: 40px">
    <button fxFlex="100" style="margin-top: 16px" class="mat-neutral" mat-raised-button
            (click)="connexionInvite()">
        <h2 class="bleu">FREE ACCESS</h2>
    </button>
    <button fxFlex="100" style="margin-top: 16px" class="mat-neutral" mat-raised-button
            routerLink="/connexion">
        <h2 class="orange">ALL STAR ACCESS</h2>
    </button>
</div>
