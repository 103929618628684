import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs/internal/observable/of';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EntiteService} from '../services/entite.services';
import * as PoubelleActions from './poubelle.action';
import {ParseService} from "../services/parse.service";
import * as ArticleActions from "./article.action";
import {Article} from "./article.model";

@Injectable()
export class ArticleEffect {
    postArticle$ = createEffect(() => this.actions$.pipe(
        ofType(ArticleActions.SET_ARTICLE),
        switchMap((action: ArticleActions.SetArticle) => {
            return this.entite.postEntite(this.parse.entity(action.payload), Article, Article.REST)
                .pipe(map((article: Article) => ({
                    type: ArticleActions.SET_ARTICLE_SUCCES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
        })));

    delArticle$ = createEffect(() => this.actions$.pipe(
        ofType(ArticleActions.DEL_ARTICLE),
        switchMap((action: ArticleActions.SetArticle) => {
            return this.entite.delEntite(Article, Article.REST, action.payload.id.toString())
                .pipe(map((article: Article) => ({
                    type: ArticleActions.DEL_ARTICLE_SUCCES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
        })));

    reloadArticle$ = createEffect(() => this.actions$.pipe(
        ofType(ArticleActions.DEL_ARTICLE_SUCCES),
        switchMap((action: ArticleActions.SetArticleSucces) => {
            return of(true)
                .pipe(map(_ => ({
                    type: ArticleActions.GET_ARTICLES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
        })));

    getArticles$ = createEffect(() => this.actions$.pipe(
        ofType(ArticleActions.GET_ARTICLES),
        switchMap((action: ArticleActions.GetArticles) =>
            this.entite.getEntites(null, Article, Article.REST)
                .pipe(map((articles: Article[]) => ({
                    type: ArticleActions.GET_ARTICLES_SUCCES,
                    payload: articles
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    resetArticles$ = createEffect(() => this.actions$.pipe(
        ofType(ArticleActions.SET_ARTICLE_SUCCES),
        switchMap(() =>
            of(true)
                .pipe(map(() => ({
                    type: ArticleActions.GET_ARTICLES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    constructor(private actions$: Actions, private entite: EntiteService, private parse: ParseService) {
    }
}
