import {Component, OnInit} from '@angular/core';
import {Sport} from '../../models/sport.model';
import {SportService} from '../../services/sport.service';
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import * as SportActions from './../../models/sport.action';
import { take } from 'rxjs/operators';
import {Observable} from "rxjs";
import * as ArticleActions from "../../models/article.action";

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {

    sports$: Observable<Sport[]>;

    constructor(public sport: SportService, private store: Store<AppState>) {
        this.sports$ = this.store.select('sports');

        this.sports$.subscribe((sports: Sport[]) => {
            if (sports.length > 0 && this.sport.nothingSelected) {
                this.sport.select(sports[0]);
            }
        });
    }

    ngOnInit(): void {
        this.store.dispatch(new SportActions.GetSports());
        this.store.dispatch(new ArticleActions.GetArticles());
    }

    setSport(sport) {
        this.sport.select(sport);
    }
}
