import {Action} from '@ngrx/store';
import {Account} from './account.model';

export const SET_ACCOUNT = '[ACCOUNT] Set';
export const SET_ACCOUNT_SUCCES = '[ACCOUNT] Set Succes';

export class SetAccount implements Action {
    readonly type = SET_ACCOUNT;

    constructor(public payload: Account) {
    }
}

export class SetAccountSucces implements Action {
    readonly type = SET_ACCOUNT_SUCCES;

    constructor(public payload: Account[]) {
    }
}

export type Actions =
    SetAccount |
    SetAccountSucces;
