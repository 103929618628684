import * as PronoActions from './prono.action';
import {Prono} from './prono.model';

export function PronoReducer(state: Prono[], action: PronoActions.Actions) {
    switch (action.type) {
        case PronoActions.GET_PRONOS_SUCCES:
            return action.payload;
        default:
            return state !== undefined ? state : [];
    }
}

