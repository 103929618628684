import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Store} from '@ngrx/store';
import {EntiteListe} from '../models/entite-liste.interface';
import {Entite} from '../models/entite.model';
import {Parametres} from '../models/parametres.interface';
import {Jeton} from '../models/jeton.model';
import {AppState} from '../app.state';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private readonly parametresObligatoires: string[];
    private headers: HttpHeaders;
    private callRoot = `https://beta.sports-us-pronos.ovh/rest`;
    // private callRoot = `http://localhost:3000`;

    constructor(private http: HttpClient, private cookies: CookieService, private store: Store<AppState>) {
        // this.parametresObligatoires = [
        //     'pIndice'
        // ];
        this.store.select('jeton').subscribe((jeton: Jeton) => {
            if (jeton !== undefined && jeton.value !== null) {
                this.headers = (new HttpHeaders()).set('US-SPORTS-TOKEN', jeton.value);
            } else {
                this.headers = new HttpHeaders();
            }
        });
    }

    public setJeton(jeton: Jeton) {
        this.headers = (new HttpHeaders()).set('US-SPORTS-TOKEN', jeton.value);
    }

    public getListe(parametres: Parametres, ...urlComposants: string[]): Observable<EntiteListe> {
        // this.verifierParametres(parametres);
        return this.http.get<EntiteListe>(`${this.callRoot}/${urlComposants.join('/')}`, {
            headers: this.headers,
            params: parametres
        });
    }

    public getEntites(parametres: Parametres, ...urlComposants: string[]): Observable<Entite[]> {
        return this.http.get<Entite[]>(`${this.callRoot}/${urlComposants.join('/')}`, {
            headers: this.headers,
            params: parametres
        });
    }

    public putEntites(obj: object, ...urlComposants: string[]): Observable<Entite[]> {
        return this.http.put<Entite[]>(`${this.callRoot}/${urlComposants.join('/')}`, obj, {headers: this.headers});
    }

    public getEntite(parametres: Parametres, ...urlComposants: string[]): Observable<Entite> {
        return this.http.get<Entite>(`${this.callRoot}/${urlComposants.join('/')}`, {
            headers: this.headers,
            params: parametres
        });
    }

    public putEntite(obj: object, ...urlComposants: string[]): Observable<Entite> {
        return this.http.put<Entite>(`${this.callRoot}/${urlComposants.join('/')}`, obj, {headers: this.headers});
    }

    // private verifierParametres(parametres: {[p: string]: string | string[]}) {
    //     let parametresVerifies = true;
    //     for (const parametreObligatoire of this.parametresObligatoires) {
    //         parametresVerifies = parametresVerifies && Object.keys(parametres).includes(parametreObligatoire);
    //     }
    //     if (!parametresVerifies) {
    //         throw new Error('Les paramètres obligatoires ne sont pas renseignés.');
    //     }
    // }

    public post(obj: object, ...urlComposants: string[]): Observable<Entite> {
        return this.http.post<Entite>(`${this.callRoot}/${urlComposants.join('/')}`, obj, {headers: this.headers});
    }

    public postEntites(obj: object, ...urlComposants: string[]): Observable<Entite[]> {
        return this.http.post<Entite[]>(`${this.callRoot}/${urlComposants.join('/')}`, obj, {headers: this.headers});
    }

    // public put(obj: object, ...urlComposants: string[]): Observable<Entite> {
    //     return this.http.put<Entite>(`${this.callRoot}/${urlComposants.join('/')}`, obj, {headers: this.headers});
    // }

    public delete(...urlComposants: string[]): Observable<object> {
        return this.http.delete(`${this.callRoot}/${urlComposants.join('/')}`, {headers: this.headers});
    }

    // public getURL(url): Observable<Entite[]> {
    //     return this.http.get<Entite[]>(url, {headers: this.headers});
    // }
}
