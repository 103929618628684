import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppState} from '../../app.state';
import {Store} from '@ngrx/store';
import * as ArticleActions from '../../models/article.action';
import {Article} from '../../models/article.model';
import {SessionService} from '../../services/session.service';
import {SnackbarService} from '../../services/snackbar.service';
import {Errors} from '../../enums/errors.enum';
import {ArticleService} from '../../services/article.service';
import {Observable} from 'rxjs';
import {Actions, ofType} from '@ngrx/effects';
import {take} from 'rxjs/operators';
import {ConfirmationDialog} from '../confirmation-modale/confirmation-modale.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
    articleForm: FormGroup;
    errorMessages: any = Errors;
    article: Article;
    mode = 'liste';
    articles$: Observable<Article[]>;
    configTinyMCE: any = {
        height: 350,
        menubar: false,
        plugins: [
            'advlist autolink lists link image charmap print',
            'preview anchor searchreplace visualblocks code',
            'fullscreen insertdatetime media table paste',
            'help wordcount'
        ],
        toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            link image | \
            bullist numlist outdent indent | removeformat | help'
    };
    tinyKey = 'ogf25z4jh7b38mm84otgy083rtwu8dxqadwq1u5rojgl8ske';

    constructor(private store: Store<AppState>, private actions: Actions, public session: SessionService, private snack: SnackbarService, public articles: ArticleService, private dialog: MatDialog) {
        this.articleForm = new FormGroup({
            title: new FormControl('', [Validators.required]),
            content: new FormControl('ignore', [Validators.required]),
            isFreeAccess: new FormControl(false),
            isAdminAccess: new FormControl(false),
        });

        this.articles$ = this.store.select('articles');

        this.articles.selectedArticle$.subscribe((article) => {
            if (article) {
                this.article = article;
                this.articleForm.controls.title.setValue(article.title);
                this.articleForm.controls.content.setValue(article.content);
                this.articleForm.controls.isFreeAccess.setValue(article.isFreeAccess);
                this.articleForm.controls.isAdminAccess.setValue(article.isAdminAccess);
            }
        });
    }

    setArticle() {
        if (!this.articleForm.valid) {
            this.articleForm.markAllAsTouched();
            this.snack.openError('Les champs sont obligatoires');
            return;
        }
        const article = new Article({...this.article, ...this.articleForm.getRawValue()});
        this.store.dispatch(new ArticleActions.SetArticle(article));

        this.actions.pipe(ofType(ArticleActions.SET_ARTICLE_SUCCES)).pipe(take(1)).subscribe(() => {
            this.selectArticle(null);
        });
    }

    ngOnInit(): void {
    }

    selectArticle(article?: Article) {
        if (article === null) {
            this.mode = 'liste';
        } else {
            this.mode = 'edit';
        }
        this.articles.select(article);
    }

    addArticle() {
        this.mode = 'edit';
        this.articles.select(new Article());
    }

    supprimerArticle(article: Article) {
        let dialogRef = this.dialog.open(ConfirmationDialog, {
            disableClose: false
        });
        dialogRef.componentInstance.confirmMessage = 'Veuillez confirmer cette action.';

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.store.dispatch(new ArticleActions.DelArticle(article));
            }
            dialogRef = null;
        });
    }
}
