// @ts-ignore-file
import {Injectable} from '@angular/core';
import {Entite} from '../models/entite.model';
import * as moment from 'moment';
import {isMoment, Moment} from 'moment';
@Injectable({
    providedIn: 'root'
})
export class ParseService {
    constructor() {
    }
    communParse(obj: object) {
        const parsedobj = {...obj};
        for (const prop in obj) {
            if (obj[prop] !== null && typeof obj[prop] === 'string' && obj[prop].length === 0) {
                parsedobj[prop] = null;
            } else if (obj[prop] !== null && typeof obj[prop] === 'object') {
                if (isMoment(obj[prop])) {
                    if (this.isFunction(obj[prop].toISOString)) {
                        parsedobj[prop] = obj[prop].toISOString(true);
                    }
                } else if (Array.isArray(obj[prop])) {
                    parsedobj[prop] = [];
                    obj[prop].forEach(element => {
                        parsedobj[prop].push(this.entity(element));
                    });
                } else {
                    parsedobj[prop] = this.entity(obj[prop]);
                }
            }
        }
        return parsedobj;
    }
    isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }
    entity(entity: Entite): Entite {
        const parsedobj = {...entity};
        for (const prop in entity) {
            const value = entity[prop];
            if (entity[prop] !== null && typeof entity[prop] === 'object') {
                if (isMoment(entity[prop])) {
                    parsedobj[prop] = this.cleanMomentDateToISOstring(value);
                }
            }
        }
        return this.communParse(parsedobj) as Entite;
    }
    cleanNumbersInObject(obj: object, keys: string[]) {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                if (keys.includes(prop)) {
                    obj[prop] = this.cleanStrToNumber(obj[prop]);
                } else if (typeof obj[prop] === 'object') {
                    this.cleanNumbersInObject(obj[prop], keys);
                }
            }
        }
        return obj;
    }
    unmaskObject(obj: object) {
        const parsedobj = {...obj};
        for (const prop in obj) {
            const value = obj[prop];
            parsedobj[prop] = this.cleanStr(value);
        }
        return parsedobj;
    }
    forceUpperCase(str: string) {
        return str.toUpperCase();
    }
    cleanStrToNumber(str): number {
        return typeof str === 'string' ? str.length === 0 ? null : parseFloat(this.unmask(str)) : str;
    }
    cleanStr(str) {
        return this.unmask(str);
    }
    cleanMomentDateToISOstring(date) {
        return date.toISOString(true);
    }
    unmask(text: string): string {
        if (text === null) {
            return '';
        } else if (typeof text !== 'string') {
            return text;
        }
        return text.replace(/_/g, '').replace(/€/g, '').replace(/%/g, '').replace(/ /g, '').replace(/,/g, '.');
    }
    deepCopy(obj) {
        let copy;
        // Handle the 3 simple types, and null or undefined
        if (null == obj || 'object' !== typeof obj) {
            return obj;
        }
        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }
        if (isMoment(obj)) {
            const refobj = obj as Moment;
            return moment(new Date(refobj.toDate()));
        }
        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                if (obj.hasOwnProperty(attr)) {
                    copy[attr] = this.deepCopy(obj[attr]);
                }
            }
            return copy;
        }
        throw new Error('Unable to copy obj! Its type isn\'t supported.');
    }
    removeNullProperties(obj: Object) {
        const objWithoutNull = {};
        for (const property in obj) {
            if (obj[property] !== null && (!Array.isArray(obj[property]) || obj[property].length !== 0)) {
                objWithoutNull[property] = obj[property];
            }
        }
        return objWithoutNull;
    }
}
