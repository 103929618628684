import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from "@angular/fire/database";

@Injectable({
    providedIn: 'root'
})
export class TchatService {

    public ref: AngularFireList<unknown[]>;

    constructor(private db: AngularFireDatabase) {
        this.ref = db.list('salon_general');
    }

    initRef() {
        // console.log(this.ref);
    }

    sendMessage() {

    }
}
