import {AfterViewInit, Component, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {TchatService} from "./services/tchat.service";
import {SessionService} from "./services/session.service";
import {SportService} from "./services/sport.service";
import {MatSidenav} from "@angular/material/sidenav";
import {AppState} from "./app.state";
import {Store} from "@ngrx/store";
import {Sport} from "./models/sport.model";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'sportsuspronos';

    @ViewChild('drawerleft') public drawerleft: MatSidenav;
    @ViewChild('drawerright') public drawerright: MatSidenav;
    sidenav: string = 'discord';
    logoClass: string = 'sportsus';

    constructor(private store: Store<AppState>, private tchat: TchatService, public session: SessionService, public sport: SportService) {
        moment.locale('fr-FR');
        this.tchat.initRef();
        this.sport.selectedSport$.subscribe((sport: Sport) => {
            switch (sport.id) {
                case 1:
                    this.logoClass = 'hockey';
                    break; // HOCKEY_SUR_GLACE
                case 2:
                    this.logoClass = 'football-americain';
                    break; // FOOTBALL_AMERICAIN
                case 3:
                    this.logoClass = 'tennis';
                    break; // TENNIS
                case 4:
                    this.logoClass = 'rugby';
                    break; // RUGBY
                case 5:
                    this.logoClass = 'basketball';
                    break; // BASKETBALL
                case 6:
                    this.logoClass = 'football';
                    break; // FOOTBALL
                case 7:
                    this.logoClass = 'baseball';
                    break; // BASEBALL
                default:
                    this.logoClass = 'sportsus';
                    break;
            }
        });
    }

    ngAfterViewInit(): void {
        this.sport.refDrawerLeft = this.drawerleft;
        this.sport.refDrawerRight = this.drawerright;
    }
}
