<mat-toolbar fxLayout="row">
    <div fxFlex></div>
    <h2 class="bleu">ARTICLES</h2>
    <div fxFlex></div>
</mat-toolbar>

<div fxLayout="row wrap" *ngIf="mode === 'edit'">
    <button fxFlex="100" style="margin-right: 4px; margin-top: 16px; width: 100%" (click)="selectArticle(null)"
            class="mat-neutral" mat-raised-button>
        <h2 class="orange">Retour Liste</h2>
    </button>
    <div fxFlex="100" style="margin: 16px 0;" fxLayout="row wrap">
        <mat-slide-toggle fxFlex="100"
                          [formControl]="articleForm.controls.isAdminAccess"
                          color="primary">
            {{articleForm.controls.isAdminAccess.value ? 'Visible pour les utilisateurs' : 'Invisible pour les utilisateurs'}}
        </mat-slide-toggle>
        <mat-slide-toggle fxFlex="100"
                          [formControl]="articleForm.controls.isFreeAccess"
                          color="primary">
            {{articleForm.controls.isFreeAccess.value ? 'Visible pour les invités' : 'Invisible pour les invités'}}
        </mat-slide-toggle>
    </div>
    <mat-card fxFlex="100">
        <form [formGroup]="articleForm" fxLayout="column" fxLayoutAlign="center">
            <mat-form-field appearance="outline">
                <mat-label>Titre</mat-label>
                <input autocomplete="off" required matInput formControlName="title">
                <mat-error *ngFor="let error of articleForm.controls.title.errors | keyvalue">
                    <span *ngIf="articleForm.controls.title.hasError(error.key)">{{errorMessages[error.key]}}</span>
                </mat-error>
            </mat-form-field>
            <div appearance="outline">
                <mat-label>Contenu</mat-label>
                <editor [apiKey]="tinyKey"
                        autocomplete="off" required formControlName="content"
                        [init]="configTinyMCE"
                ></editor>
            </div>
            <button fxFlex style="margin-right: 4px; margin-top: 16px; width: 100%" (click)="setArticle()"
                    class="mat-neutral" mat-raised-button>
                <h2 class="orange">Enregistrer</h2>
            </button>
        </form>
    </mat-card>
</div>

<div fxLayout="row wrap" *ngIf="mode === 'liste'">
    <button fxFlex="100" style="margin-right: 4px; margin-top: 16px; width: 100%" (click)="addArticle()"
            class="mat-neutral" mat-raised-button>
        <h2 class="orange">Ajouter</h2>
    </button>
    <mat-card class="article" fxFlex="100" *ngFor="let article of articles$ | async">
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>
                {{article.title}}
                <span style="font-size: 0.6em !important; display: block;"
                      *ngIf="session.isAdminOrRedac()">Visible par {{article.isFreeAccess && article.isAdminAccess ? 'tout le monde' : this.article.isAdminAccess ? 'les all stars' : this.article.isFreeAccess ? 'les invités' : 'personne'}}.</span>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <br/>
            <p [innerHTML]="article.content | safeHtml"></p>
        </mat-card-content>
        <mat-card-actions fxLayout="column">
            <button fxFlex="100" style="margin: 8px 0" (click)="selectArticle(article)" class="mat-neutral"
                    mat-raised-button>
                <h2 class="orange">Modifier</h2>
            </button>
            <button fxFlex="100" style="margin: 8px 0" (click)="supprimerArticle(article)" class="mat-neutral"
                    mat-raised-button>
                <h2 class="orange">Supprimer</h2>
            </button>
        </mat-card-actions>
    </mat-card>
</div>
