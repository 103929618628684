import {Entite} from './entite.model';
import {Moment} from 'moment';
import {Account} from "./account.model";

export class Jeton extends Entite {
    static REST = 'authentification';
    account: Account;
    value: string;
    lastuse: Moment;
    revoke: boolean;

    constructor({
                    id = null,
                    account = null,
                    value = null,
                    lastuse = null,
                    revoke = null
                } = {}) {
        super({id});
        this.id = id;
        this.account = account !== null ? new Account(account) : new Account();
        this.value = value;
        this.lastuse = lastuse;
        this.revoke = revoke;
    }
}