import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RefreshResolver} from "./services/refresh.resolver";
import {ConnexionComponent} from "./components/connexion/connexion.component";
import {SportsusComponent} from "./components/sportsus/sportsus.component";
import {ListePronosComponent} from "./components/liste-pronos/liste-pronos.component";
import {EditPronosComponent} from "./components/edit-pronos/edit-pronos.component";
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        resolve: {
            ignoredboolean: RefreshResolver,
        }
    },
    {
        path: 'connexion',
        component: ConnexionComponent,
        resolve: {
            ignoredboolean: RefreshResolver,
        }
    },
    {
        path: '',
        component: SportsusComponent,
        resolve: {
            ignoredboolean: RefreshResolver,
        },
        children: [
            {
                path: 'liste',
                component: ListePronosComponent
            },
            {
                path: 'fiche',
                component: EditPronosComponent
            },
            {
                path: 'fiche/:idprono',
                component: EditPronosComponent
            },
            {
                path: '**',
                redirectTo: 'liste',
            }
        ]
    },
    {
        path: '**',
        redirectTo: 'home',
    }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
