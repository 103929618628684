<div (click)="moveToHome()" style="text-align: center">
    <h2 class="bleu">SPORTS US</h2>
    <h2 class="orange">PRONOS</h2>
</div>

<form (ngSubmit)="envoyerFormulaire()" [formGroup]="connexionForm" fxLayout="column" fxLayoutAlign="center">
    <mat-form-field appearance="outline">
        <mat-label>Identifiant</mat-label>
        <input formControlName="pseudo" matInput required autocomplete="off"
               type="text"/>
        <mat-error *ngFor="let error of connexionForm.controls.pseudo.errors | keyvalue">
            <span *ngIf="connexionForm.controls.pseudo.hasError(error.key)">{{messagesErreur[error.key]}}</span>
        </mat-error>
    </mat-form-field>
    <input-mot-de-passe [passControl]="connexionForm.controls.pwd"
                        [required]="true"></input-mot-de-passe>
    <button class="mat-neutral" mat-raised-button type="submit">
        <h2 class="orange">Connexion</h2>
    </button>
</form>
<!--    <a class="clickable" routerLink="r">Mot de passe oublié ?</a>-->
