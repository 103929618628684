import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Jeton} from '../models/jeton.model';
import {Store} from '@ngrx/store';
import * as JetonActions from '../models/jeton.action';
import {Account} from '../models/account.model';
import {AppState} from "../app.state";
import {SportService} from "./sport.service";

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor(private store: Store<AppState>, private sport: SportService) {
        this._jeton$ = this.store.select('jeton');
        this._jeton$.subscribe((jeton: Jeton) => {
            this._accountConnecte = jeton !== undefined && jeton.value !== null ? jeton.account : null;
        });
    }

    private _jeton$: Observable<Jeton>;
    get jeton$(): Observable<Jeton> {
        return this._jeton$;
    }

    // tslint:disable-next-line:variable-name
    private _accountConnecte: Account;
    get accountConnecte(): Account {
        if (this._accountConnecte === null) {
            return new Account();
        }
        return this._accountConnecte;
    }

    // tslint:disable-next-line:variable-name
    private _vientDeSeConnecter: boolean;
    get vientDeSeConnecter(): boolean {
        return this._vientDeSeConnecter;
    }

    set vientDeSeConnecter(value: boolean) {
        this._vientDeSeConnecter = value;
    }

    deconnexion(message = null) {
        if (message !== null) {
            this.store.dispatch(new JetonActions.FermerJeton(message));
        } else {
            this.store.dispatch(new JetonActions.FermerJeton());
        }
    }

    isAdminOrRedac(): boolean {
        return this.accountConnecte.type.name === 'Admin' || this.accountConnecte.type.name === 'Redac';
    }
}
