import {Injectable} from '@angular/core';
import {Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class KillsubscribeService {

    private _ks: Subscription;

    constructor () {

    }

    get ks(): Subscription {
        return this._ks;
    }

    set ks(value: Subscription) {
        if (this.ks !== undefined) {
           this.ks.unsubscribe();
           delete this.ks;
        }
        this._ks = value;
    }
}
