import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    public mobile$: Observable<boolean> = this.breakpointObserver
        .observe('(max-width: 1279px)')
        .pipe(map((result: BreakpointState) => result.matches));
    constructor(private breakpointObserver: BreakpointObserver) {
    }
}