<mat-toolbar fxLayout="row">
    <div fxFlex></div>
    <h2 class="bleu">COMPTE</h2>
    <div fxFlex></div>
</mat-toolbar>

<mat-card>
    <form [fxHide]="(session.jeton$ | async).account.type.name === 'Invite'" [formGroup]="accountForm" fxLayout="column" fxLayoutAlign="center">
        <mat-form-field appearance="outline">
            <mat-label>Pseudo</mat-label>
            <input autocomplete="off" required matInput formControlName="pseudo">
            <mat-error *ngFor="let error of accountForm.controls.pseudo.errors | keyvalue">
                <span *ngIf="accountForm.controls.pseudo.hasError(error.key)">{{errorMessages[error.key]}}</span>
            </mat-error>
        </mat-form-field>
        <input-mot-de-passe [passControl]="accountForm.controls.pwd" label="Nouveau mot de passe"
                            [required]="true"></input-mot-de-passe>
        <input-mot-de-passe [passControl]="accountForm.controls.pwdconfirm" label="Confirmation du mot de passe"
                            [required]="true"></input-mot-de-passe>
        <button fxFlex style="margin-right: 4px; margin-top: 16px; width: 100%" (click)="setAccount()" class="mat-neutral" mat-raised-button>
            <h2 class="orange">Enregistrer</h2>
        </button>
    </form>
    <br/>
    <button style="margin-right: 4px; margin-top: 16px; width: 100%" (click)="deconnecter()" class="mat-neutral" mat-raised-button>
        <h2 class="bleu">Deconnexion</h2>
    </button>
</mat-card>
