import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    showSpinner$: Subject<boolean>;
    loadingstack: number;
    constructor() {
        this.showSpinner$ = new Subject();
        this.loadingstack = 0;
    }
    forceHide() {
        this.loadingstack = 0;
        this.hide();
    }
    hide() {
        this.showSpinner$.next(false);
    }
    show() {
        this.showSpinner$.next(true);
    }
}