import {Component, OnInit} from '@angular/core';
import {SportService} from "../../services/sport.service";

@Component({
    selector: 'app-header-mobile',
    templateUrl: './header-mobile.component.html',
    styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

    constructor(public sport: SportService) {
    }

    ngOnInit(): void {
    }

}
