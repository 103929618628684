<mat-expansion-panel *ngIf="!prono.isCombine">
    <mat-expansion-panel-header>
        <mat-panel-title style="margin: 0">
            <span class="date-bet">
                <mat-icon class="star" *ngIf="!prono.isFreeAccess">star_rate</mat-icon>
                <h4 class="orange" style="margin: 0">{{prono.dateMatch.format('L')}}</h4>
            </span>
        </mat-panel-title>
        <mat-panel-description style="margin: 0">
            <span class="condition" fxFlex style="text-align: left;">{{prono.condition}}</span>
            <span class="bet" style="padding: 4px" [ngClass]="{'bad-bet': prono.result === false, 'good-bet': prono.result === true}">*** {{prono.cote | number:'.2-2'}} ***</span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div fxLayout="column">
        <div fxLayout="row" fxFlex>
            <div fxFlex>
                <h3 class="bleu">{{prono.team1}}</h3>
            </div>
            <div fxFlex>
                <h4 style="margin-left: 32px" class="orange">VS</h4>
            </div>
            <div fxFlex="">
                <h3 class="bleu">{{prono.team2}}</h3>
            </div>
        </div>
        <div fxLayout="row wrap">
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button (click)="validerProno()">
                <h4 class="orange">GOOD BET</h4>
            </button>
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button (click)="invaliderProno()">
                <h4 class="orange">BAD BET</h4>
            </button>
            <h4 fxFlex="100" style="margin-top: 16px">Bet par {{prono.account.pseudo}}</h4>
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button [routerLink]="['/fiche/', prono.id]">
                <h4 class="orange">MODIFIER</h4>
            </button>
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button (click)="deleteProno()">
                <h4 class="orange">SUPPRIMER</h4>
            </button>
        </div>
    </div>
</mat-expansion-panel>

<mat-expansion-panel *ngIf="prono.isCombine">
    <mat-expansion-panel-header>
        <mat-panel-title style="margin: 0">
            <span class="date-bet">
                <mat-icon class="star" *ngIf="!prono.isFreeAccess">star_rate</mat-icon>
                <h4 class="orange" style="margin: 0">{{prono.dateMatch.format('L')}}</h4>
            </span>
        </mat-panel-title>
        <mat-panel-description style="margin: 0">
            <span class="condition" fxFlex style="text-align: left;">{{prono.condition}}</span>
            <span class="bet" style="padding: 4px" [ngClass]="{'bad-bet': determineResult(prono) === false, 'good-bet': determineResult(prono) === true}">*** {{prono.cote | number:'.2-2'}} ***</span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div fxLayout="column" *ngFor="let combi of prono.combines">
        <div fxLayout="row" fxFlex>
            <h4 class="orange" style="margin: 0">{{combi.dateMatch.format('L')}}</h4>
        </div>
        <div fxLayout="row" fxFlex>
            <div fxFlex>
                <h3 class="bleu">{{combi.team1}}</h3>
            </div>
            <div fxFlex>
                <h4 style="margin-left: 32px" class="orange">VS</h4>
            </div>
            <div fxFlex style="text-align: right">
                <h3 class="bleu">{{combi.team2}}</h3>
            </div>
        </div>
        <div fxLayout="row" fxFlex>
            <div fxFlex>
                <h3 class="bleu">{{combi.condition}}</h3>
            </div>
            <div fxFlex>
                <h4 style="margin-left: 32px" class="orange"></h4>
            </div>
            <div fxFlex style="text-align: right">
                <span style="padding: 4px" [ngClass]="{'bad-bet': combi.result === false, 'good-bet': combi.result === true}">* {{combi.cote | number:'.2-2'}} *</span>
            </div>
        </div>
        <div fxLayout="row wrap">
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button (click)="validerProno(combi)">
                <h4 class="orange">GOOD BET</h4>
            </button>
            <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                    mat-raised-button (click)="invaliderProno(combi)">
                <h4 class="orange">BAD BET</h4>
            </button>
        </div>
    </div>
    <div fxLayout="row wrap">
        <h4 fxFlex="100" style="margin-top: 16px;">Combi par {{prono.account.pseudo}}</h4>
        <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                mat-raised-button [routerLink]="['/fiche/', prono.id]">
            <h4 class="orange">MODIFIER</h4>
        </button>
        <button fxFlex="50" class="mat-neutral small" *ngIf="hasEditRights()"
                mat-raised-button (click)="deleteProno()">
            <h4 class="orange">SUPPRIMER</h4>
        </button>
    </div>
</mat-expansion-panel>
