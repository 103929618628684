import {ErrorHandler, Injectable} from '@angular/core';
import {EnvService} from './env.service';
import {SessionService} from './session.service';
import {environment} from '../../environments/environment';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

    constructor(private env: EnvService, private session: SessionService) {
    }

    handleError(err) {
        if (environment.production) {
            console.error(err);
        } else {
            console.error(err);
        }
    }
}
