<h2 mat-dialog-title>Confirmer la suppression ?</h2>
<mat-dialog-content class="mat-typography">

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="mat-neutral" mat-raised-button mat-dialog-close>
        <h3 class="bleu">Retour</h3>
    </button>
    <button class="mat-neutral" mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>
        <h3 class="orange">Supprimer</h3>
    </button>
</mat-dialog-actions>
