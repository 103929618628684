import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {Entite} from '../models/entite.model';
import {EntiteListe} from '../models/entite-liste.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Identifiant} from '../models/identifiant.interface';
import {Version} from '../models/version.interface';
import {Parametres} from '../models/parametres.interface';

@Injectable({
    providedIn: 'root'
})
export class EntiteService {
    constructor(private resource: ResourceService) {
    }

    public construireEntiteEnProfondeur(classe: typeof Entite, donneesBrut: any): Entite {
        // L'UTILITÉ PRINCIPALE DE CETTE FONCTION EST DE CRÉER UN OBJECT DYNAMIQUEMENT À PARTIR DE DONNEES BRUT DU BACKEND
        const nouvelleEntite = new classe(donneesBrut);
        // L'UTILITÉ BONUS DE CETTE FONCTION EST D'INSTANCIER LES COLLECTONS D'OBJETS DES DONNEES BRUT DU BACKEND
        // IL ÉTAIT POSSIBLE DE LE FAIRE DIRECTEMENT DANS L'OBJECT, MAIS CELA CRÉÉ DES RÉFERENCES CIRCULAIRES
        for (let entiteLiee of nouvelleEntite.entitesLiees) {
            const collection_brut = donneesBrut[entiteLiee.nomPropriete];
            nouvelleEntite[entiteLiee.nomPropriete] = [];
            collection_brut.forEach((entite) => {
                nouvelleEntite[entiteLiee.nomPropriete].push(new entiteLiee.classe(entite));
            });
        }
        return nouvelleEntite;
    }

    public getListe(parametres: Parametres, classe: typeof Entite, ...urlComponents: string[]): Observable<EntiteListe> {
        return this.resource.getListe(parametres, ...urlComponents)
            .pipe(map((entiteListe: EntiteListe) => {
                entiteListe.contenu = entiteListe.contenu.map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite));
                return entiteListe;
            }));
    }

    public getEntites(parametres: Parametres, classe: typeof Entite, ...urlComponents: string[]): Observable<Entite[]> {
        return this.resource.getEntites(parametres, ...urlComponents)
            .pipe(map((entiteListe: Entite[]) => {
                return entiteListe.map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite));
            }));
    }

    public putEntites(entite: Entite[], classe: typeof Entite, ...urlComponents: string[]): Observable<Entite[]> {
        return this.resource.putEntites(entite, ...urlComponents)
            .pipe(map((entiteListe: Entite[]) => {
                return entiteListe.map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite));
            }));
    }

    public getEntite(parametres: Parametres, classe: typeof Entite, ...urlComponents: string[]): Observable<Entite> {
        return this.resource.getEntite(parametres, ...urlComponents)
            .pipe(map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite)));
    }

    public postEntite(obj: Entite | Identifiant | Version, classe: typeof Entite, ...urlComponents: string[]): Observable<Entite> {
        return this.resource.post(obj, ...urlComponents)
            .pipe(map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite)));
    }

    public postEntites(entite: Entite[], classe: typeof Entite, ...urlComponents: string[]): Observable<Entite[]> {
        return this.resource.postEntites(entite, ...urlComponents)
            .pipe(map((entiteListe: Entite[]) => {
                return entiteListe.map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite));
            }));
    }

    public putEntite(entite: Entite, classe: typeof Entite, ...urlComponents: string[]): Observable<Entite> {
        return this.resource.putEntite(entite, ...urlComponents)
            .pipe(map((entite: Entite) => this.construireEntiteEnProfondeur(classe, entite)));
    }

    public delEntite(classe: typeof Entite, ...urlComponents: string[]): Observable<object> {
        return this.resource.delete(...urlComponents);
    }
}
