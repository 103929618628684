import {Action} from '@ngrx/store';

export const POUBELLE_ACTION = '[POUBELLE] Poubelle';

export class Poubelle_Action implements Action {
    readonly type = POUBELLE_ACTION;

    constructor() {
    }
}

export type Actions = Poubelle_Action;