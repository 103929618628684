import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Errors} from '../../enums/errors.enum';
import {fromEvent, Subscription} from 'rxjs';
import {MatDatepickerInput} from '@angular/material/datepicker';

@Component({
    selector: 'inputdatepicker',
    templateUrl: './inputdatepicker.component.html',
    styleUrls: ['./inputdatepicker.component.scss']
})
export class InputdatepickerComponent implements OnInit, AfterViewInit {
    @Output() enterpressed = new EventEmitter();
    @Input() readonly: boolean;
    @Input() readonlyLooksDisabled: boolean;
    @Input() passFormGroup: FormGroup;
    @Input() passControl: AbstractControl;
    @Input() passControlName: string;
    @Input() placeholder: string;
    @Input() required = false;
    errorMessages = Errors;
    eventSubscription: Subscription;
    @ViewChild('inputmatpicker', {static: false}) inputmatpicker: ElementRef;
    @ViewChild(MatDatepickerInput, {static: false}) datepickerinput: MatDatepickerInput<any>;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.eventSubscription = fromEvent(this.inputmatpicker.nativeElement, 'input').subscribe(_ => {
            this.datepickerinput._onInput(this.inputmatpicker.nativeElement.value);
        });
    }

    send($event = null) {
        if ($event !== null) {
            $event.preventDefault();
        }
        this.enterpressed.emit($event);
    }
}
