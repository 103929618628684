import {Action} from '@ngrx/store';
import {Prono} from './prono.model';

export const GET_PRONOS = '[PRONO] Get pronos';
export const GET_PRONOS_SUCCES = '[PRONO] Get pronos Succes';
export const DEL_PRONO = '[PRONO] Del pronos';
export const DEL_PRONO_SUCCES = '[PRONO] Del pronos Succes';
export const GET_PRONO = '[PRONO] Get prono';
export const GET_PRONO_SUCCES = '[PRONO] Get prono Succes';
export const SET_PRONOS = '[PRONO] Set';
export const SET_PRONO_SUCCES = '[PRONO] Set Succes';
export const SET_COMBINES = '[COMBINE] Set';
export const SET_COMBINES_SUCCES = '[COMBINE] Set Succes';

export class GetPronos implements Action {
    readonly type = GET_PRONOS;
}

export class GetPronosSucces implements Action {
    readonly type = GET_PRONOS_SUCCES;

    constructor(public payload: Prono[]) {
    }
}

export class GetProno implements Action {
    readonly type = GET_PRONO;

    constructor(public payload: Prono) {
    }
}

export class GetPronoSucces implements Action {
    readonly type = GET_PRONO_SUCCES;

    constructor(public payload: Prono) {
    }
}

export class DelProno implements Action {
    readonly type = DEL_PRONO;

    constructor(public payload: Prono) {
    }
}

export class DelPronoSucces implements Action {
    readonly type = DEL_PRONO_SUCCES;

    constructor() {
    }
}

export class SetPronos implements Action {
    readonly type = SET_PRONOS;

    constructor(public payload: Prono, public refresh: boolean = true) {
    }
}

export class SetPronoSucces implements Action {
    readonly type = SET_PRONO_SUCCES;
}

export class SetCombines implements Action {
    readonly type = SET_COMBINES;

    constructor(public payload: Prono) {
    }
}

export class SetCombinesSucces implements Action {
    readonly type = SET_COMBINES_SUCCES;
}

export type Actions =
    GetPronos |
    GetPronosSucces |
    GetProno |
    GetPronoSucces |
    SetPronos |
    SetPronoSucces |
    SetCombines |
    SetCombinesSucces;
