import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AppState} from "../../app.state";
import {Store} from "@ngrx/store";
import * as AccountActions from '../../models/account.action';
import * as JetonActions from '../../models/jeton.action';
import {Account} from "../../models/account.model";
import {SessionService} from "../../services/session.service";
import {SnackbarService} from "../../services/snackbar.service";
import {Errors} from "../../enums/errors.enum";
import {Jeton} from "../../models/jeton.model";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    accountForm: FormGroup;
    errorMessages: any = Errors;

    constructor(private store: Store<AppState>, public session: SessionService, private snack: SnackbarService) {
        this.accountForm = new FormGroup({
            'pseudo': new FormControl('', [Validators.required]),
            'pwd': new FormControl('ignore', [Validators.required]),
            'pwdconfirm': new FormControl('ignore', [Validators.required])
        });

        this.session.jeton$.subscribe((jeton: Jeton) => {
            if (jeton.account.id !== null) {
                this.accountForm.controls.pseudo.setValue(jeton.account.pseudo);
            }
        })
    }

    ngOnInit(): void {
    }

    setAccount() {
        if (this.accountForm.invalid) {
            this.accountForm.markAllAsTouched();
            return;
        }
        if (this.accountForm.controls.pwd.value !== this.accountForm.controls.pwdconfirm.value) {
            this.snack.openError('Les mots de passe doivent être identiques.');
            return;
        }
        const account = new Account({
            ...this.session.accountConnecte
            , ...this.accountForm.getRawValue()
        });
        this.store.dispatch(new AccountActions.SetAccount(account));
    }

    deconnecter() {
        this.store.dispatch(new JetonActions.FermerJeton());
    }
}
