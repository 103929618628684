import {Component, OnInit} from '@angular/core';
import * as JetonActions from "../../models/jeton.action";
import {AppState} from "../../app.state";
import {Identifiant} from "../../models/identifiant.interface";
import {Store} from "@ngrx/store";
import {Article} from "../../models/article.model";
import {Observable} from "rxjs";
import {SessionService} from "../../services/session.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    articles$: Observable<Article[]>;

    constructor(private store: Store<AppState>, private session: SessionService) {
        this.articles$ = this.store.select('articles');
    }

    ngOnInit(): void {
        if (this.session.accountConnecte.id !== null) {
            this.session.deconnexion('Vous avez été deconnecté.');
        }
    }

    connexionAllStar() {

    }

    connexionInvite() {
        const invite = {pseudo: 'invite', pwd: 'invite'} as Identifiant;
        this.store.dispatch(new JetonActions.ConnexionJeton(invite));
    }
}
