import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs/internal/observable/of';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EntiteService} from '../services/entite.services';
import * as PoubelleActions from './poubelle.action';
import * as AccountActions from './account.action';
import {Account} from './account.model';
import {ParseService} from "../services/parse.service";
import * as JetonActions from "./jeton.action";
import {Jeton} from "./jeton.model";

@Injectable()
export class AccountEffect {
    postAccount$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActions.SET_ACCOUNT),
        switchMap((action: AccountActions.SetAccount) => {
            let subcr = null;
            if (action.payload.id !== null) {
                return this.entite.putEntite(this.parse.entity(action.payload), Account, Account.REST)
                    .pipe(map((account: Account) => ({
                        type: AccountActions.SET_ACCOUNT_SUCCES
                    })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
            }
            return this.entite.postEntite(this.parse.entity(action.payload), Account, Account.REST)
                .pipe(map((account: Account) => ({
                    type: AccountActions.SET_ACCOUNT_SUCCES
                })), catchError(() => of({type: PoubelleActions.POUBELLE_ACTION})))
        })));

    rafraichirAccount$ = createEffect(() => this.actions$.pipe(
        ofType(AccountActions.SET_ACCOUNT_SUCCES),
        switchMap((action: AccountActions.SetAccountSucces) =>
            this.entite.getEntite(null, Jeton, Jeton.REST)
                .pipe(map(jeton => ({type: JetonActions.RAFRAICHIR_JETON_SUCCES, payload: jeton, message: 'Compte mis à jour !'})),
                    catchError(() => of({type: PoubelleActions.POUBELLE_ACTION}))))));

    constructor(private actions$: Actions, private entite: EntiteService, private parse: ParseService) {
    }
}
