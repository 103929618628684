import {Component, Input, OnInit} from '@angular/core';
import {Errors} from '../../enums/errors.enum';

@Component({
    selector: 'input-mot-de-passe',
    templateUrl: './input-mot-de-passe.component.html',
    styleUrls: ['./input-mot-de-passe.component.scss']
})
export class InputMotDePasseComponent implements OnInit {
    @Input() label: string;             // Libellé du champ
    @Input() passControl: any;          // FormControl en question
    @Input() required: boolean = false; // Si on doit afficher une étoile (*)
    afficher = false;
    messagesErreur = Errors;

    constructor() {
    }

    ngOnInit() {
    }
}
