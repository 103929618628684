import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})


export class EnvService {
    constructor(private resource: ResourceService, private dialog: MatDialog) {
    }

    public getEnvironment(): string {
        return 'us_pronos';
    }

    public getTypeJeton(): string {
        return 'beta'
    }

    public getDomain(): string {
        return 'beta.sports-us-pronos.ovh';
    }

    public pingVersion(): void {
        // const version: Version = {versionCode: this.version.current};
        // this.resource.post(version, 'ping', 'version').subscribe((response: any) => {
        //     if (!response.hasOwnProperty('version_ok')) {
        //         const dialogConfig: MatDialogConfig = new MatDialogConfig();
        //         dialogConfig.disableClose = true;
        //         const dialogRef: MatDialogRef<ModaleVersionComponent> = this.dialog.open(ModaleVersionComponent, dialogConfig);
        //         dialogRef.afterClosed().subscribe((result: boolean) => {
        //             if (result) {
        //                 location.reload();
        //             }
        //         });
        //     }
        // });
    }
}
