import * as JetonActions from './jeton.action';
import {Jeton} from './jeton.model';

export function JetonReducer(state: Jeton, action: JetonActions.Actions) {
    // console.log(action)
    switch (action.type) {
        case JetonActions.CONNEXION_JETON_SUCCES:
            return action.payload;
        case JetonActions.RAFRAICHIR_JETON_SUCCES:
            return action.payload;
        case JetonActions.FERMER_JETON_SUCCES:
            return new Jeton();
        default:
            return state !== undefined ? state : new Jeton();
    }
}

